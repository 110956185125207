import { GAEvent } from "./analytics"

const gaEvents = {
  userGetReferralLink: new GAEvent<{page: string}>('user_get_referral_link'),
  userClaimChallengeReward: new GAEvent<{challenge: string, prize: string}>('user_claim_challenge_reward'),
  userOpenAirdropBoxReward: new GAEvent<{box: string}>('user_open_airdrop_box_reward'),
  whatsNewPopupEvent: new GAEvent<{type: 'DISPLAY' | 'DISMISS', id: string}>('whats_new_popup')
}

export default gaEvents;
