import { RemoteValue } from './remote_config';

export interface WhatsNewPopupData {
  id: string,
  date: string,
  coverMedia: string,
  description: string,
  languages: string[],
  sections: { title: string, content: string }[]
}

const rcValues = {
  bannerReward: new RemoteValue('banner_reward'),
  whatsNewPopup: new RemoteValue<WhatsNewPopupData[]>('whats_new_popup')
};

export default rcValues;
