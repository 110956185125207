import { ApolloClient, ApolloLink, gql } from '@apollo/client';
import React, { createContext, useContext } from 'react';
import { AirdropBalance, AirdropBox, AirdropBoxOpenedInfo, AirdropQuestsByCurrency, AirdropRank, AirdropSeasonChallenge, AirdropSeasonChallengeClaimableBox, AirdropSeasonChallengeCompleted, AirdropSeasonResume, AirdropWave, AvailableAirdropBox, CategoryAndQuests } from '../models/airdrop';
import { getWithHttpLink, initializeApollo, linkWithoutHttpLink } from '../services/apolloClient';
import { Wave } from '../models/quests';
import { toNumber } from 'lodash';

const apolloClient = initializeApollo();

function getApolloClientForDevice(device_id: string) {
  const deviceIdHeaderLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => ({
      headers: {
        ...headers,
        'authorization': undefined,
        'x-device-id': device_id,
      },
    }));

    return forward(operation);
  });

  const customApolloClient = new ApolloClient({
    ssrMode: apolloClient.ssrMode,
    link: getWithHttpLink(linkWithoutHttpLink.concat(deviceIdHeaderLink)),
    cache: apolloClient.cache,
    defaultOptions: apolloClient.defaultOptions,
  });

  return customApolloClient;
}

interface AirdropContextData {
  getAirdropBalance: (device_id?: string, currency?: string) => Promise<AirdropBalance>;
  getAirdropRank: (device_id?: string, currency?: string) => Promise<AirdropRank>;
  getAirdropWave: (wave_id: string, device_id?: string) => Promise<AirdropWave>;
  getAirdropSeasonResume: (season_id: string, device_id?: string) => Promise<AirdropSeasonResume>;
  getAirdropSeasonUserScore: (season_id: string, device_id?: string, currency?: string) => Promise<AirdropBalance>;
  getAirdropSeasonScoreboard: (season_id: string, device_id?: string, currency?: string) => Promise<AirdropRank>;
  getAirdropSeasonChallenges: (season_id: string, device_id?: string) => Promise<AirdropSeasonChallenge[]>;
  completeAirdropSeasonChallenge: (season_id: string, challenge_id: string) => Promise<AirdropSeasonChallengeCompleted>;
  openAvailableAirdropBoxReward: (prize_id: string) => Promise<AirdropBoxOpenedInfo>;
  getAvailableAirdropBoxRewards: () => Promise<AvailableAirdropBox[]>;
  getAirdropBox: (airdrop_box_id: string) => Promise<AirdropBox>;
  getClamaibleAirdropBoxRewardsForChallenges: (season_id: string) => Promise<AirdropSeasonChallengeClaimableBox[]>;
  getAirdropAvailableQuests: (company_id: string, starting_wave: number) => Promise<CategoryAndQuests[]>;
  getAidropQuestsByCompanyId: (company_id: string) => Promise<AirdropQuestsByCurrency[]>;
}

const AirdropContext = createContext<AirdropContextData>({} as AirdropContextData);

export const AirdropProvider: React.FC = ({ children }) => {
  const getAirdropBalance = async (device_id?: string, currency?: string): Promise<AirdropBalance> => {
    return new Promise<AirdropBalance>((resolve, reject) => {
      const client = device_id ? getApolloClientForDevice(device_id) : apolloClient;

      client.query({
        query: gql`
            query AirdropBalance($currency: String) {
              airdropBalance(currency: $currency) {
                account_id
                currency
                amount
                user_position_rank
              }
            }
          `,
        variables: {
          currency,
        },
      })
        .then(async response => {
          resolve(response?.data?.airdropBalance);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const getAirdropWave = async (wave_id: string, device_id?: string): Promise<AirdropWave> => {
    return new Promise<AirdropWave>((resolve, reject) => {
      const client = device_id ? getApolloClientForDevice(device_id) : apolloClient;

      client.query({
        query: gql`
            query AirdropWave($wave_id: String!) {
              airdropWave(wave_id: $wave_id) {
                wave {
                  id
                  name
                  description
                  image
                  sort
                  is_completed
                }
                categories_and_quests {
                  quest_category {
                    id
                    name
                    sort
                  }
                  quests {
                    id
                    title
                    description
                    type
                    reward_currency
                    reward_amount
                    airdrop_reward {
                      id
                      account_id
                      currency
                      amount
                    }
                    quest_link {
                      initial_url
                      url
                      time_seconds
                    }
                    sort
                  }
                }
              }
            }
          `,
        variables: {
          wave_id,
        },
      })
        .then(async response => {
          const airdropWave = response?.data?.airdropWave;
          airdropWave.categories_and_quests = airdropWave.categories_and_quests.map(function (categoryAndQuests) {
            const newCategoriesAndQuests = categoryAndQuests;

            newCategoriesAndQuests.quests = newCategoriesAndQuests.quests.map(function (quest) {
              quest.is_rewarded = quest.airdrop_reward ? true : false;
              return quest;
            });

            return newCategoriesAndQuests;
          })

          resolve(response?.data?.airdropWave);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const getAirdropRank = async (device_id?: string, currency?: string): Promise<AirdropRank> => {
    return new Promise<AirdropRank>((resolve, reject) => {
      const client = device_id ? getApolloClientForDevice(device_id) : apolloClient;

      client.query({
        query: gql`
            query AirdropRank($currency: String) {
              airdropRank(currency: $currency) {
                user_position_rank
                rank {
                  account_id
                  currency
                  amount
                  position
                }
              }
            }
          `,
        variables: {
          currency,
        },
      })
        .then(async response => {
          resolve(response?.data?.airdropRank);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const getAirdropSeasonResume = async (season_id: string, device_id?: string): Promise<AirdropSeasonResume> => {
    return new Promise<AirdropSeasonResume>((resolve, reject) => {
      const client = device_id ? getApolloClientForDevice(device_id) : apolloClient;

      client.query({
        query: gql`
          query airdropSeasonResume($season_id: String!) {
            airdropSeasonResume(season_id: $season_id) {
              user_stats {
                quests_completed
                referral_count
                satoshi_earned
                total_points
              }
              overall_stats {
                quests_completed
                referral_count
                satoshi_earned
                total_points
              }
            }
          }
        `,
        variables: {
          season_id
        }
      })
        .then(async response => {
          resolve(response?.data?.airdropSeasonResume);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const getAirdropSeasonUserScore = async (season_id: string, device_id?: string, currency?: string): Promise<AirdropBalance> => {
    return new Promise<AirdropBalance>((resolve, reject) => {
      const client = device_id ? getApolloClientForDevice(device_id) : apolloClient;

      client.query({
        query: gql`
          query airdropSeasonUserScore($season_id: String!, $currency: String) {
            airdropSeasonUserScore(season_id: $season_id, currency: $currency) {
              account_id
              currency
              amount
              user_position_rank
            }
          }
        `,
        variables: {
          season_id,
          currency
        }
      })
        .then(async response => {
          resolve(response?.data?.airdropSeasonUserScore);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const getAirdropSeasonScoreboard = async (season_id: string, device_id?: string, currency?: string): Promise<AirdropRank> => {
    return new Promise<AirdropRank>((resolve, reject) => {
      const client = device_id ? getApolloClientForDevice(device_id) : apolloClient;

      client.query({
        query: gql`
          query airdropSeasonScoreboard($season_id: String!, $currency: String) {
            airdropSeasonScoreboard(season_id: $season_id, currency: $currency) {
              user_position_rank
              rank{
                account_id
                currency
                amount
                position
              }
            }
          }
        `,
        variables: {
          season_id,
          currency
        }
      })
        .then(async response => {
          resolve(response?.data?.airdropSeasonScoreboard);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const getAirdropSeasonChallenges = async (season_id: string, device_id?: string): Promise<AirdropSeasonChallenge[]> => {
    return new Promise<AirdropSeasonChallenge[]>((resolve, reject) => {
      const client = device_id ? getApolloClientForDevice(device_id) : apolloClient;

      client.query({
        query: gql`
          query airdropSeasonChallenges($season_id: String!) {
            airdropSeasonChallenges(season_id: $season_id) {
              id
              slug
              airdrop_season_id
              reward_airdrop_box_id
              frequency
              goal
              streak
              go_url
              user_progress {
                progress
                progress_percent
                can_claim_reward
                reward_claimed_at
              }
            }
          }
        `,
        variables: {
          season_id
        }
      })
        .then(async response => {
          resolve(response?.data?.airdropSeasonChallenges);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const completeAirdropSeasonChallenge = async (season_id: string, challenge_id: string): Promise<AirdropSeasonChallengeCompleted> => {
    return new Promise<AirdropSeasonChallengeCompleted>((resolve, reject) => {
      apolloClient.query({
        query: gql`
          mutation completeAirdropSeasonChallenge($season_id: String!, $challenge_id: String!) {
            completeAirdropSeasonChallenge(season_id: $season_id, challenge_id: $challenge_id) {
              id
              prize_available {
                box {
                  id
                  name
                  image
                }
              prize_id
            }
          }
        }
        `,
        variables: {
          season_id,
          challenge_id
        }
      })
        .then(async response => {
          resolve(response?.data?.completeAirdropSeasonChallenge);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const openAvailableAirdropBoxReward = async (prize_id: string): Promise<AirdropBoxOpenedInfo> => {
    return new Promise<AirdropBoxOpenedInfo>((resolve, reject) => {
      apolloClient.query({
        query: gql`
          mutation($prize_id: String!) {
            openAvailableAirdropBox(prize_id: $prize_id) {
              prize_id
              opened_at
              rewarded_items {
                id
                name
                type
                currency
                amount
              }
            }
          }
        `,
        variables: {
          prize_id
        }
      })
        .then(async response => {
          resolve(response?.data?.openAvailableAirdropBox);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const getAvailableAirdropBoxRewards = async (): Promise<AvailableAirdropBox[]> => {
    return new Promise<AvailableAirdropBox[]>((resolve, reject) => {
      apolloClient.query({
        query: gql`
          query availableAirdropBoxes {
            availableAirdropBoxes {
              box {
                id
                name
                image
              }
              prize_id
            }
          }
        `
      })
        .then(async response => {
          resolve(response?.data?.availableAirdropBoxes);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const getAirdropBox = async (airdrop_box_id: string): Promise<AirdropBox> => {
    return new Promise<AirdropBox>((resolve, reject) => {
      apolloClient.query({
        query: gql`
          query airdropBox($airdrop_box_id: String!){
            airdropBox(box_id: $airdrop_box_id){
              id,
              name,
              image
            }
          }
        `,
        variables: {
          airdrop_box_id
        }
      })
        .then(async response => {
          resolve(response?.data?.airdropBox);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const getClamaibleAirdropBoxRewardsForChallenges = async (season_id: string): Promise<AirdropSeasonChallengeClaimableBox[]> => {
    return new Promise<AirdropSeasonChallengeClaimableBox[]>(async (resolve) => {
      const clamaibleChallengeRewards = (await getAirdropSeasonChallenges(season_id))
        .filter((challenge) => {
          const completableOnce = !challenge.go_url && challenge.goal === '1';
          const userProgress = challenge.user_progress;

          const isDirectlyClamaible = completableOnce
            && userProgress?.reward_claimed_at === null

          if (isDirectlyClamaible) {
            return true;
          }

          const canClaimReward = userProgress !== null
            && userProgress?.progress_percent >= 100
            && userProgress?.can_claim_reward
            && userProgress?.reward_claimed_at === null;

          return canClaimReward;
        });

      const airdropBoxRewardsInfo: Record<string, AirdropBox> = {};
      const clamaibleChallengeRewardBoxes: AirdropSeasonChallengeClaimableBox[] = [];

      for (const challenge of clamaibleChallengeRewards) {
        if (!(challenge.reward_airdrop_box_id in airdropBoxRewardsInfo)) {
          airdropBoxRewardsInfo[challenge.reward_airdrop_box_id] = await getAirdropBox(challenge.reward_airdrop_box_id);
        }

        clamaibleChallengeRewardBoxes.push({
          challenge: challenge,
          box: airdropBoxRewardsInfo[challenge.reward_airdrop_box_id]
        });
      }

      resolve(clamaibleChallengeRewardBoxes)
    });
  };

  const getAirdropAvailableQuests = async (company_id: string, starting_wave: number): Promise<CategoryAndQuests[]> => {
    return new Promise<CategoryAndQuests[]>(async (resolve, reject) => {
      const waves = (await new Promise<Wave[]>((resolve, reject) => {
        apolloClient.query({
          query: gql`
            query WavesByCompanyId($company_id: String!) {
              wavesByCompanyId(company_id: $company_id) {
                id
                name
                description
                image
                sort
                is_completed
              }
            }
          `,
          variables: {
            company_id,
          },
        })
          .then(async response => {
            resolve(response?.data?.wavesByCompanyId);
          })
          .catch(error => {
            reject(error.response?.data ? error.response.data : error.message);
          });
      }))
        .filter((v) => toNumber(v.sort) >= (starting_wave - 1))
        .sort((a, b) => toNumber(a.sort) - toNumber(b.sort));

      const airdropQuestsAndCategories: CategoryAndQuests[] = [];

      for (const wave of waves) {
        if (wave.is_completed) {
          continue;
        }

        const airdropWave = await getAirdropWave(wave.id);
        airdropQuestsAndCategories.push(...airdropWave.categories_and_quests);
      }

      resolve(airdropQuestsAndCategories.map((v) => {
        v.quests = v.quests.filter((q) => !q.is_rewarded);
        return v;
      }).filter((v) => v.quests.length > 0));
    });
  }

  const getAidropQuestsByCompanyId = async (company_id: string): Promise<AirdropQuestsByCurrency[]> => {
    return new Promise<AirdropQuestsByCurrency[]>(async (resolve, reject) => {
      apolloClient.query({
        query: gql`
          query airdropQuests($company_id: String!) {
            airdropQuests(company_id: $company_id) {
              currency
              quests {
                id
                title
                description
                type
                reward_currency
                reward_amount
                airdrop_reward {
                  id
                  account_id
                  currency
                  amount
                }
                quest_link {
                  id
                  initial_url
                  url
                  time_seconds
                  event_reward
                }
                quest_action {
                  id
                  type
                  initial_url
                  url
                }
                sort
              }
            }
          }
        `,
        variables: {
          company_id,
        }
      })
        .then(async response => {
          resolve(response?.data?.airdropQuests);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  }

  return (
    <AirdropContext.Provider value={{ getAirdropBalance, getAirdropRank, getAirdropWave, getAirdropSeasonResume, getAirdropSeasonUserScore, getAirdropSeasonScoreboard, getAirdropSeasonChallenges, completeAirdropSeasonChallenge, openAvailableAirdropBoxReward, getAvailableAirdropBoxRewards, getAirdropBox, getClamaibleAirdropBoxRewardsForChallenges, getAirdropAvailableQuests, getAidropQuestsByCompanyId }}>
      {children}
    </AirdropContext.Provider>
  );
};

function useAirdrop(): AirdropContextData {
  const context = useContext(AirdropContext);

  if (!context) {
    throw new Error('useAirdrop must be used within an AirdropProvider');
  }
  return context;
}

export { AirdropContext, useAirdrop };
