import { ensureInitialized, fetchAndActivate, getValue, Value } from 'firebase/remote-config';
import { remoteConfig } from "./firebase";

type RemoteConfigValueName = Lowercase<string>;

class RemoteValue<T extends unknown> {
  name: RemoteConfigValueName;

  constructor(name: RemoteConfigValueName) {
    this.name = name;
  }

  get(): T {
    return (JSON.parse(this.asString())) as T;
  }

  async getAsync(): Promise<T> {
    await ensureInitialized(remoteConfig)
      .then(() => fetchAndActivate(remoteConfig));

    return this.get();
  }

  getValue(): Value {
    return getValue(remoteConfig, this.name);
  }

  asBoolean(): boolean {
    return this.getValue().asBoolean();
  }

  asNumber(): number {
    return this.getValue().asNumber();
  }

  asString(): string {
    return this.getValue().asString();
  }
}

export { RemoteValue };
