import styled from "styled-components";

export const WhatsNewPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const WhatsNewContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .coverMediaVideo {
    border: none;
    box-shadow: 2px 2px 0px #000000;
    background: #000000;
    border-radius: 12px;
    margin: 0px 0px;
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    margin-bottom: 10px;
  }

  .markdownSection {
    margin-bottom: 16px;

    h1 {
      font-size: 20px;
      margin-bottom: 8px;
      padding-left: 10px;
      border-left: 6px solid #F44805;
      color: #F44805;
    }

    p {
      margin-bottom: 12px;
    }
  }

  li {
    margin-bottom: 8px;
    margin-left: 12px;
  }
`;

export const WhatsNewCoverMediaImage = styled.img`
  border: none;
  box-shadow: 2px 2px 0px #000000;
  background: #000000;
  border-radius: 12px;
  margin: 0px auto;
  width: 100%;
  height: auto;
  max-width: 240px;
  margin-bottom: 10px;
`;

export const WhatsNewPopupTitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    margin-top: 10px;
    margin-bottom: 4px;
  }

  p {
    font-size: 10px;
    font-weight: 600;
    font-style: italic;
    margin-left: 4px;
  }
`;
